import { BUILDING_TYPE, HOME_INTERNET_SERVICE_TYPE } from '@/store/api/service-home-internet/service-home-internet.type';
import { ServiceItem } from '@/store/slice/home-internet.slice';
import { EVPATORIA_NAME } from '@/shared/constants/diy';
import { formatPrice } from '@/shared/lib/utils';
import { 
  detectPromoData,
  homeInternetDefaultServicesMapper,
  HomeInternetDefaultPrices, 
  constructorDetector, 
  MobilePricesCounterArgs, 
  InternetPromotionDescriptionMakerArgs, 
  ShpdPriceCounterArgs, 
  ShpdPrices, 
  mobileTariffShpdResponseMapper,
  mobileShpdSliceMapper,
  HomeInternetDefaultPriceCounterArgs,
  MobilePrices
} from '.';

export const homeInternetDefaultPriceCounter = ({ tariff, buildingType = BUILDING_TYPE.APARTMENT } : HomeInternetDefaultPriceCounterArgs): HomeInternetDefaultPrices => {
  /**
   * есть ли МС
   */
  const isConvergent = Boolean(tariff?.tariff_mobile_shpds?.data?.length && tariff?.tariff_mobile_shpds?.data?.length > 0);
  const mobile = isConvergent ? mobileTariffShpdResponseMapper(tariff?.tariff_mobile_shpds)?.[0] : undefined;
  const mobileTariffShpd = mobile ? mobileShpdSliceMapper(mobile) : undefined;
  /**
   * услуги подключенные по умолчанию
   */
  const homeInternetDefaultServices = homeInternetDefaultServicesMapper(tariff?.default_services);
  /**
   * скорость в услугах по умолчанию
   */
  const speed = tariff?.default_services?.data.find(({ attributes }) => 
    attributes.service_type === HOME_INTERNET_SERVICE_TYPE.SPEED && attributes?.building_type === buildingType);
  /**
   * тв в услугах по умолчанию
   */
  const tv = tariff?.default_services?.data.find(({ attributes }) => 
    attributes.service_type === HOME_INTERNET_SERVICE_TYPE.TV && attributes?.building_type === buildingType);
  /**
   * расчет общей цены и цены с акцией
   */
  const { totalBasketPrice: defaultPrice, totalBasketPriceWithPromotion: promoDefaultPrice } = shpdPricesCounter({ 
    speed: speed?.attributes as unknown as ServiceItem,
    tv: tv?.attributes as unknown as ServiceItem,
    homeInternetTouchedServices: homeInternetDefaultServices,
    buildingType: buildingType,
    mobileTariffShpd,
    isEvpatoria: tariff?.name === EVPATORIA_NAME,
    isConvergent,
  });
  /**
   * ломается ли скидка на конвергент
   */
  const promotionIsAffected = mobileTariffShpd?.affects_shpd_promotion_ids?.some((id) => speed?.attributes?.shpd_id === id);
  /**
   * есть ли акция
   */
  const hasPromotion = (isConvergent && !promotionIsAffected && promoDefaultPrice < defaultPrice) || (!isConvergent && promoDefaultPrice < defaultPrice);
  /**
   * информация об акции из услуг по умолчанию
   */
  const { period, description } = detectPromoData({
    buildingType,
    homeInternetTouchedServices: homeInternetDefaultServicesMapper(tariff?.default_services)
  });
  /**
   * полная информация об акции
   */
  const promotion = hasPromotion ?
    {
      promotionPrice: promoDefaultPrice,
      promotionDescription: description,
      promotionPeriod: period,
    } :
    undefined;

  const isConstructor = constructorDetector(tariff?.name);

  return {
    price: defaultPrice,
    priceFrom: isConstructor ? promoDefaultPrice : undefined, //для конструкторов
    promotion,
  };
};

export const mobilePricesCounter = ({
  mobileTariff,
  mobileTouchedServices,
  mobileTariffShpd,
  speedId,
  isEvpatoria,
  buildingType = BUILDING_TYPE.APARTMENT,
}: MobilePricesCounterArgs): MobilePrices => {
  const basePrice = mobileTariff?.price || 0;
  /**
   * итоговая цена услуг
   */
  const servicesPrice =
    mobileTouchedServices?.reduce((acc, { added, servicePrice }) => (acc += added ? servicePrice : 0), 0) || 0; 

  /**
   * цена услуг без акций
   */
  const servicesPriceWithoutPromotions = mobileTouchedServices?.reduce((acc, { added, servicePrice, oldPrice }) => {
    const currentPrice = oldPrice || servicePrice;

    return acc += added ? currentPrice : 0;
  }, 0) || 0;

  /**
   * итоговая цена МС + итоговая цена услуг, без акций
  */
  const totalPrice = basePrice + servicesPriceWithoutPromotions;

  /**
   * есть ли акция в тарифе МС или в услугах
  */
  const withPromotion = valueIsNumber(mobileTariff?.promotion_price) || servicesPriceWithoutPromotions > servicesPrice;

  /**
   * итоговая цена МС + итоговая цена услуг, если есть акции
  */
  const promotionTotalPrice = withPromotion ? (mobileTariff?.promotion_price || basePrice) + servicesPrice : null;

  //--- цены для конвергентов ---//

  const priceType = isEvpatoria ? 'evpatoria_price' : buildingType === BUILDING_TYPE.HOUSE ? 'price_house' : 'price_apartment';
  /**
   * цена МС ШПД без акций
  */
  const shpdMobilePriceWithoutPromotions = mobileTariffShpd?.shpd_prices?.[priceType] || 0;
  /**
   * цена МС ШПД + услуги, без акций
  */
  const totalPriceShpd = shpdMobilePriceWithoutPromotions + servicesPriceWithoutPromotions;
  /**
   * ломается ли скидка на скорость
  */
  const shpdAffectsPromotion = mobileTariffShpd?.affects_shpd_promotion_ids?.some((item) => item === speedId);
  /**
   * есть ли скидка на МС ШПД или услуги
  */
  const shpdMobileHasPromotion = (!shpdAffectsPromotion && valueIsNumber(mobileTariffShpd?.shpd_prices?.promotion_price) && !isEvpatoria ) 
    || servicesPriceWithoutPromotions > servicesPrice;

  /**
   * итоговая цена МС ШПД + итоговая цена услуг, если есть акции
  */
  const promotionTotalPriceShpd = shpdMobileHasPromotion ? (mobileTariffShpd?.shpd_prices?.promotion_price || 0) + servicesPrice : null;

  return {
    totalPrice,
    promotionTotalPrice,
    totalPriceShpd,
    promotionTotalPriceShpd,
  };
};

export const shpdPricesCounter = ({
  speed,
  tv,
  homeInternetTouchedServices,
  mobileTariffShpd,
  mobileTouchedServices,
  isEvpatoria,
  isConvergent,
  buildingType,
}: ShpdPriceCounterArgs): ShpdPrices => {
  const priceType = isEvpatoria ? 'evpatoria_price' : isConvergent ? 'convergent_price' : 'mono_price';
  /**
   * базовая цена скорости
  */
  const speedBasePrice = speed?.prices?.[priceType];
  /**
   * цена скорости со скидкой
  */
  const speedPromotionPrice = valueIsNumber(speed?.prices?.promotion_price) ? speed?.prices?.promotion_price || 0 : speedBasePrice || 0;
  /**
   * есть ли скидка на скорость
  */
  const speedHasPromotion = speedPromotionPrice < (speedBasePrice || 0) && !isEvpatoria;
  /**
   * итоговая цена на скорость
  */
  const speedPrice = speedHasPromotion ? speedPromotionPrice : speedBasePrice;
  /**
   * цена ТВ (постоянна)
  */
  const tvPrice = tv?.prices?.[priceType];
  /**
   * услуги, которые были добавлены
  */
  const addedServices = homeInternetTouchedServices?.filter(({ added }) => added);
  /**
   * полная цена на услуги без скидки
  */
  const servicesPrice = addedServices?.reduce((acc, { prices }) => acc += prices?.[priceType] || 0, 0);
  /**
   * цена на услуги со скидкой
  */
  const servicesPriceWithPromotion = addedServices.reduce((acc, { prices }) => {
    return  acc += valueIsNumber(prices?.promotion_price)
      ? prices?.promotion_price || 0
      : prices?.[priceType] || 0;
  }, 0);
  /**
   * итоговая цена без скидок
  */
  const totalPrice = (speedBasePrice || 0) + (tvPrice || 0) + servicesPrice;
  /**
   * есть ли акция в услугах
  */
  const servicesHasPromotion = servicesPrice > servicesPriceWithPromotion;
  /**
   * итоговая цена со скидкой для моно тарифа или для Евпатории
  */
  const promotionTotalPriceMonoOrEvpatoria = (servicesHasPromotion || speedHasPromotion) ? 
    (speedPrice || 0) + (tvPrice || 0) + servicesPriceWithPromotion : null;

  if (!isConvergent) {
    return {
      totalPrice,
      promotionTotalPrice: promotionTotalPriceMonoOrEvpatoria,
      totalBasketPrice: totalPrice,
      totalBasketPriceWithPromotion: promotionTotalPriceMonoOrEvpatoria || totalPrice,
    };
  }
  //--- цены на конвергенты ---//

  /**
   * ломается ли скидка на скорость
  */
  const promotionIsAffected = mobileTariffShpd?.affects_shpd_promotion_ids?.some((item) => item === speed?.shpd_id);
  /**
   * есть ли скидка на конвергент
  */
  const convergentHasPromotion = (!promotionIsAffected && speedHasPromotion) || servicesHasPromotion;
  /**
   * итоговая цена со скидкой для конвергента
  */
  const promotionTotalPriceConvergent = isEvpatoria ? promotionTotalPriceMonoOrEvpatoria : convergentHasPromotion ? 
    (speed?.prices?.promotion_price || speedBasePrice || 0) + (tvPrice || 0) + servicesPriceWithPromotion 
    : null;

  const {
    totalPriceShpd: mobileTotalPrice,
    promotionTotalPriceShpd: promotionTotalPriceMobile,
  } = mobilePricesCounter({
    mobileTariffShpd,
    isEvpatoria,
    mobileTouchedServices,
    speedId: speed?.shpd_id,
    buildingType,
  });

  /**
   * итоговая цена на МС
  */
  const  mobilePrice = valueIsNumber(promotionTotalPriceMobile) ? promotionTotalPriceMobile || 0 : mobileTotalPrice;
  /**
   * сумма цен МС + ДИ + услуги без скидок
  */
  const totalBasketPrice = totalPrice + mobileTotalPrice;
  /**
   * сумма цен МС + ДИ + услуги со скидками
  */
  const totalBasketPriceWithPromotion = (promotionTotalPriceConvergent || totalPrice) + mobilePrice;

  return {
    totalPrice,
    promotionTotalPrice: promotionTotalPriceConvergent,
    totalBasketPrice,
    totalBasketPriceWithPromotion,
    mobileShpdPrices: {
      totalPrice: mobileTotalPrice,
      promotionTotalPrice: promotionTotalPriceMobile,
    },
  };
};

export const valueIsNumber = (val: number | null | undefined) => {
  if (typeof val === 'number' && !isNaN(val)) return true;

  return false;
};

export const internetPromotionDescriptionMaker = ({
  promotionPrice,
  promotionPeriod,
  price,
  text,
  buildingType,
}: InternetPromotionDescriptionMakerArgs) => {
  return (
    <div>
      {text ? <>{text} <br /></> : null} 
      {promotionPeriod} = {formatPrice(promotionPrice)} /мес. Далее {formatPrice(price)} /мес
      для {buildingType === BUILDING_TYPE.APARTMENT ? 'квартир' : 'частных домов'}.
    </div>
  );
};
