import React, { FC } from 'react';
import Link from 'next/link';
import { Image } from '@mantine/core';
import cn from 'classnames';
import { reachGoal } from '@/shared/lib/ym';
import { YM } from '@/shared/constants/yandex-metrika';
import { TABS } from '@/widgets/tariffs-carousel/constants';
import { mainCardLinkMaker } from '@/modules/tariff/shared/helpers';
import { Features } from './features';
import { TariffCardProps } from './tariff-card.types';
import TariffCardOption from './tariff-card-option/tariff-card-option';
import { HomeInternetCardContent } from './home-internet-card-content';
import { ButtonToHomeInternetPage } from './button-to-home-internet-page';
import styles from './tariff-card.module.scss';

const TariffCard: FC<React.PropsWithChildren<TariffCardProps>> = ({
  background_image,
  name,
  localized_name,
  price = 0,
  promotion,
  promotionPriceFrom,
  priceFrom,
  withoutPrice,
  optionsMobile = [],
  optionsHomeInternet = [],
  offer_file,
  activeTab,
  homeInternetPage,
  added,
  onClick,
  className,
  titleClassName,
  features,
  isLandingPage,
  additionalOptions,
  has_promotion_tag,
}) => {
  const actualPrice = promotion?.promotionPrice ?
    {
      price: promotion.promotionPrice,
      from: promotionPriceFrom,
    } :
    priceFrom ?
      {
        price: priceFrom,
        from: true,
      } :
      {
        price,
        from: false,
      };
  const oldPrice = priceFrom ?
    {
      price: priceFrom,
      from: true,
    } :
    {
      price,
      from: false,
    };

  const handleMobileTariffClick = () => {
    reachGoal(YM.click_cart_mobile_tarif, { name: name ?? '' });
  };

  const handleHomeInternetTariffClick = () => {
    reachGoal(YM.click_cart_home_internet, { name: name ?? '' });
  };

  const handleButtonClick = () => {
    if (activeTab === TABS.MOBILE) {
      handleMobileTariffClick();
    } else if (activeTab === TABS.INTERNET) {
      handleHomeInternetTariffClick();
    }
  };

  return (
    <div className={cn(styles.root, className, { [styles.root__homeTab]: activeTab === TABS.INTERNET })}>
      {background_image?.data && (
        <Image
          height='100%'
          width='310px'
          src={background_image?.data?.attributes?.url}
          alt={background_image?.data?.attributes?.alternativeText || ''}
          classNames={{
            root: styles.image__root,
            figure: styles.image__figure,
            imageWrapper: styles.image__wrapper,
            image: styles.image,
          }}
        />
      )}
      {has_promotion_tag ? <div className={styles.promotionTag}>Акция</div> : null}
      <div className={styles.content}>
        <div className={styles.information}>
          <span className={cn(styles.title, titleClassName && titleClassName)}>{localized_name}</span>
          {activeTab === TABS.MOBILE ? (
            optionsMobile?.map((option, index) => <TariffCardOption key={index} {...option} />)
          ) : (
            <HomeInternetCardContent name={name} optionsHomeInternet={optionsHomeInternet} mobileOptions={optionsMobile} />
          )}
          {features && features.length > 0 ? <Features data={features} /> : null}
          {additionalOptions}
        </div>
        <div className={styles.bottom}>
          {!withoutPrice && (
            <div className={styles.price__container}>
              <div className={styles.price__content}>
                {oldPrice.price !== actualPrice.price && (
                  <div className={styles.price__old}>
                    {oldPrice.from && <span>от</span>}
                    <span>{oldPrice.price} ₽</span>
                  </div>
                )}
                <div className={styles.price__actual}>
                  {actualPrice.from && (
                    <span className={cn({ [styles.price__from]: oldPrice.price === actualPrice.price })}>от</span>
                  )}
                  <span className={styles.price__number}>{actualPrice.price}₽</span>
                  <span>/мес.</span>
                </div>
              </div>
              {promotion?.promotionPeriod && (
                <div className={cn(styles.price__promotion, { [styles.price__promotion__landing]: isLandingPage })}>
                  {/* {promotion.promotion_period} {formatPrice(promotion.promotionPrice)} */}
                  {promotion.promotionPeriod}
                </div>
              )}
              
            </div>
          )}
          {homeInternetPage ? (
            <ButtonToHomeInternetPage
              activeTab={activeTab}
              added={added}
              onClick={onClick}
              isLandingPage={isLandingPage}
              offer_file={offer_file}
            />
          ) : (
            <div className={styles.buttons}>
              <Link
                href={mainCardLinkMaker(name, activeTab)}
                className={styles.buttons__more}
                onTouchStart={handleButtonClick} 
              >
                Подробнее
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TariffCard;
