import { FC } from 'react';
import Link from 'next/link';
import { Button } from '@mantine/core';
import cn from 'classnames';
import { Checkbox } from '@/shared/ui-kit';
import CheckboxRecHI from '@/shared/assets/svg/checkbox-rec-hi.svg';
import { TABS } from '@/widgets/tariffs-carousel/constants';
import { HOME_INTERNET_TARIFFS__CONSTANTS } from '@/modules/tariff/home-internet-tariffs/constants';
import styles from '../tariff-card.module.scss';

interface ButtonToHomeInternetPageProps {
  activeTab?: TABS;
  added?: boolean;
  onClick?: () => void;
  isLandingPage?: boolean;
  offer_file?: string
}

export const ButtonToHomeInternetPage: FC<ButtonToHomeInternetPageProps> = ({ activeTab, added, onClick, isLandingPage, offer_file }) => {
  return (
    <>
      {activeTab === TABS.MOBILE ? (
        <Checkbox className={styles.checkbox} added={added} onClick={onClick} />
      ) : (
        <Button
          classNames={{
            root: cn(styles.button__root, { [styles.button__added]: added }),
          }}
          onClick={onClick}
        >
          {added ? (
            <div className={styles.button__withIcon}>
              <CheckboxRecHI />
              <span>{HOME_INTERNET_TARIFFS__CONSTANTS.ADDED_BUTTON}</span>
            </div>
          ) : isLandingPage ? (
            HOME_INTERNET_TARIFFS__CONSTANTS.ORDER
          ) : (
            HOME_INTERNET_TARIFFS__CONSTANTS.ADD_BUTTON
          )}
        </Button>
      )}

      {(offer_file && offer_file.length > 1) ? (
        <Link className={styles.link} target='_blank' href={offer_file}>
          {activeTab === TABS.MOBILE
            ? HOME_INTERNET_TARIFFS__CONSTANTS.ABOUT_MOBILE_TARIFF_BUTTON
            : HOME_INTERNET_TARIFFS__CONSTANTS.ABOUT_TARIFF_BUTTON}
        </Link>
      ) : null}
    </>
  );
};
