import { BUILDING_TYPE, HOME_INTERNET_SERVICE_TYPE } from '@/store/api/service-home-internet/service-home-internet.type';
import { AdditionalServiceItem, ServiceItem } from '@/store/slice/home-internet.slice';
import { DIY_TARIFF_NAME, SEVASTOPOLSKIJ_NAME } from '@/shared/constants/diy';
import { Path } from '@/shared/constants/links';
import { TABS } from '@/widgets/tariffs-carousel/constants';
import { EVPATORIA_NAME } from '../constants';
import { DetectPromoDataArgs } from '.';

export const splitFirstWord = (text: string) => {
  const [firstWord, ...rest] = text.split(' ');

  return [firstWord, rest.join(' ')];
};

type ServiceType = 'tv' | 'equipment' | 'subscription';
type ServiceTypeParam = ServiceType[];

interface HomeInternetSlice {
  tariffName: string | undefined | null;
  tv: ServiceItem;
  buildingType: BUILDING_TYPE;
  homeInternetTouchedServices: AdditionalServiceItem[];
}

export const getGoalParams = (homeInternetSlice: HomeInternetSlice) => {
  const getSpecificTouchedService = (
    touchedServices: AdditionalServiceItem[],
    serviceType: ServiceTypeParam,
  ): string[] =>
    touchedServices.reduce<string[]>((acc, curr) => {
      if (serviceType.includes(curr.service_type as ServiceType)) {
        acc.push(curr.name);
      }

      return acc;
    }, []);

  const { tariffName, tv, buildingType, homeInternetTouchedServices } = homeInternetSlice;

  const equipment = getSpecificTouchedService(homeInternetTouchedServices, ['equipment']).filter(
    (serviceName) => !serviceName.includes('sberbox'),
  );

  const boxServices = getSpecificTouchedService(homeInternetTouchedServices, ['equipment']).filter((serviceName) =>
    serviceName.includes('sberbox'),
  );

  return {
    tariff: tariffName || '',
    services: [tv.name, ...boxServices],
    equipment,
    subscriptions: getSpecificTouchedService(homeInternetTouchedServices, ['subscription']),
    building_type: buildingType,
  };
};

export const detectPromoData = ({ buildingType, homeInternetTouchedServices }: DetectPromoDataArgs) => {
  const promo = homeInternetTouchedServices?.find(({ service_type, building_type }) => 
    service_type === HOME_INTERNET_SERVICE_TYPE.SALE && building_type === buildingType
  );

  return {
    period: promo?.promotion_period,
    description: promo?.promotion_description,
  };
};

export const constructorDetector = (name?: string, cut?: boolean) => {
  const str = cut ? name?.slice(Path.tariffHomeInternet.root.length + 1) : name;//отрезает '/home-internet/' от pathname

  return str?.includes(EVPATORIA_NAME) || str?.includes(DIY_TARIFF_NAME) || str?.includes(SEVASTOPOLSKIJ_NAME);
};

export const mainCardLinkMaker = (name?: string, activeTab?: string) => {
  const isConvergent = activeTab === TABS.INTERNET && name?.includes('convergent');
  if (isConvergent) return Path.tariffHomeInternet.convergent;

  return `${Path[activeTab === TABS.MOBILE ? 'tariffMobile' : 'tariffHomeInternet'].root}/${name}`;
};

export const specialTariffNameMaker = (tariffName?: string | null) => {
  if (tariffName?.includes('555')) return 'Тариф 555';
  if (tariffName?.includes('777')) return 'Тариф 777';
};
