import { TariffMobileShpd, TariffMobileShpdEntity } from '@/store/api/tariff-mobile-shpd/tariff-mobile-shpd.type';
import { MobileTariffShpdMappedOptions } from './helpers.types';
import { strapiCollectionResponseMapper } from '@/shared/lib/strapi-mappers';
import { tariffHomeInternetOptionsMapper } from '.';
import { DIY_TARIFF_NAME } from '@/shared/constants/diy';
import { StrapiSingleTypeResponse } from '@/shared/types/strapi.types';

export const mobileTariffShpdResponseMapper = (resp?: TariffMobileShpdEntity): MobileTariffShpdMappedOptions[] => {
  return strapiCollectionResponseMapper(resp)?.map((tariff) => {
    return {
      ...tariff,
      optionsMobile: tariff?.resource_balances,
      optionsHomeInternet: tariffHomeInternetOptionsMapper({
        tariff: tariff?.tariff_home_internets?.data?.find(({ attributes }) => attributes?.name === DIY_TARIFF_NAME)
          ?.attributes,
      }),
    };
  });
};

export const mobileTariffShpdSingleResponseMapper = (
  resp: StrapiSingleTypeResponse<TariffMobileShpd>,
): MobileTariffShpdMappedOptions => {
  const { id, attributes } = resp?.data || {};

  return {
    id,
    ...attributes,
    optionsMobile: attributes?.resource_balances,
    optionsHomeInternet: tariffHomeInternetOptionsMapper({
      tariff: attributes?.tariff_home_internets?.data?.find(({ attributes }) => attributes?.name === DIY_TARIFF_NAME)
        ?.attributes,
    }),
  };
};
