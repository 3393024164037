import { MobileService, MobileTariffShpdSliceItem, MobileTariffSliceItem } from '@/store/slice/mobile-tariff.slice';
import {
  HOME_INTERNET_SERVICE_TYPE,
  ServiceHomeInternet,
  ServiceHomeInternetEntity,
} from '@/store/api/service-home-internet/service-home-internet.type';
import { AdditionalServiceItem, ServiceItem } from '@/store/slice/home-internet.slice';
import { getFileLink } from '@/shared/lib/get-document-link';
import { TariffCardOptionProps, TariffOptionIcon } from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option.types';
import { ServiceCardProps } from '@/modules/tariff/home-internet-tariffs/services/service-card';
import { MobileTariffMappedOptions, MobileTariffShpdMappedOptions } from '.';

export const homeInternetServiceToMobileService = ({
  item,
  added,
}: {
  item: ServiceCardProps;
  added: boolean;
}): MobileService => ({
  servicePrice: item.prices.convergent_price || 0,
  title: item.localized_name,
  serv_id: item?.service?.data?.attributes?.serv_id || item.shpd_id,
  shpd_serv_id: item.shpd_id,
  description: item.description,
  added,
});

export const serviceItemToTariffCardOptionProps = (arr: ServiceItem[]): TariffCardOptionProps[] =>
  arr.map(({ service_type, localized_name }) => ({
    icon: service_type === HOME_INTERNET_SERVICE_TYPE.SPEED ? TariffOptionIcon.Speed : TariffOptionIcon.Tv,
    value: localized_name?.split(' ')[0],
    text: localized_name?.split(' ')[1],
  }));

export const homeInternetSliceMapper = (
  {
    name,
    localized_name,
    description,
    shpd_id,
    prices,
    building_type,
    service_type,
    affects_promotion_shpd,
    old_shpd_id,
  }: Omit<ServiceHomeInternet, 'tariffs' | 'priority' | 'background_image'>,
  slider_value: number,
): ServiceItem => ({
  name,
  localized_name,
  description,
  shpd_id,
  prices,
  building_type,
  service_type,
  slider_value,
  affects_promotion_shpd,
  old_shpd_id,
});

export const mobileSliceMapper = ({
  ...props
}: MobileTariffMappedOptions): MobileTariffSliceItem => ({
  localized_name: props?.localized_name,
  name: props?.name,
  text: props?.text,
  optionsMobile: props?.optionsMobile,
  price: props?.price,
  promotion_price: props?.promotion_price,
  promotion_period: props?.promotion_period,
  promotion_description: props?.promotion_description,
  features: props?.features,
  offer_file: getFileLink(props?.offer_file),
});

export const mobileShpdSliceMapper = ({
  ...props
}: MobileTariffShpdMappedOptions): MobileTariffShpdSliceItem => ({
  name: props?.name,
  localized_name: props?.localized_name,
  optionsMobile: props?.optionsMobile,
  feautures_construktor: props?.feautures_construktor,
  shpd_prices: props?.shpd_prices,
  shpd_id_apartment: props?.shpd_id_apartment,
  shpd_id_house: props?.shpd_id_house,
  shpd_pdf: getFileLink(props?.shpd_pdf),
  affects_shpd_promotion_ids: props?.affects_shpd_promotions?.data?.map(({ attributes }) => attributes.shpd_id),
});

export const homeInternetDefaultServicesMapper = (default_services?: ServiceHomeInternetEntity): AdditionalServiceItem[] => {
  return default_services?.data
    ?.filter(
      ({ attributes }) =>
        attributes?.service_type !== HOME_INTERNET_SERVICE_TYPE.SPEED &&
        attributes?.service_type !== HOME_INTERNET_SERVICE_TYPE.TV,
    )
    .map(({ attributes }) => {
      const { background_image, tariffs, ...rest } = attributes;

      return { ...rest, background_image: background_image?.data?.attributes?.url || '', added: true };
    }) || [];
};

export const homeInternetServicesMapper = (services: ServiceHomeInternet[] | undefined) : AdditionalServiceItem[] | undefined => {
  return services?.map((item) => {
    const { background_image, tariffs, priority, ...rest } = item;

    return { ...rest, background_image: background_image?.data?.attributes?.url || '', added: true };
  });
};

