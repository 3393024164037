import { TariffCardOptionProps, TariffOptionIcon } from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option.types';
import { BUILDING_TYPE, HOME_INTERNET_SERVICE_TYPE } from '@/store/api/service-home-internet/service-home-internet.type';
import { TariffMobile } from '@/store/api/tariff-mobile/tariff-mobile.type';
import { MainCardOptionMapperArgs, specialTariffNameMaker, TariffHomeInternetOptionsMapperArgs } from '.';

export const tariffMobileOptionsMapper = (tariff?: TariffMobile): TariffCardOptionProps[] => {
  const arr: TariffCardOptionProps[] = [];

  if (tariff?.traffic_value) {
    arr.push({
      icon: TariffOptionIcon.Globe,
      value: tariff.traffic_value,
      text: tariff.traffic_type,
      from: tariff.traffic_from,
    });
  }

  if (tariff?.unlimited_value) {
    arr.push({
      icon: TariffOptionIcon.Unlimited,
      value: tariff.unlimited_value,
      text: tariff.unlimited_description,
      from: false,
    });
  }

  if (tariff?.minutes_value) {
    arr.push({
      icon: TariffOptionIcon.Phone,
      value: tariff.minutes_value,
      text: tariff.minutes_description,
      from: tariff.minutes_from,
    });
  }

  if (tariff?.sms_value) {
    arr.push({
      icon: TariffOptionIcon.Email,
      value: tariff.sms_value,
      text: tariff.sms_description,
      from: tariff.sms_from,
    });
  }

  return arr;
};

export const tariffHomeInternetOptionsMapper = ({
  tariff,
  building_type = BUILDING_TYPE.APARTMENT,
}: TariffHomeInternetOptionsMapperArgs): TariffCardOptionProps[] => {
  const arr: TariffCardOptionProps[] = [];
  const speed = tariff?.default_services?.data?.find(
    ({ attributes }) =>
      attributes?.service_type === HOME_INTERNET_SERVICE_TYPE.SPEED && attributes.building_type === building_type,
  )?.attributes;
  const tv = tariff?.default_services?.data?.find(
    ({ attributes }) => attributes?.service_type === HOME_INTERNET_SERVICE_TYPE.TV,
  )?.attributes;

  if (speed) {
    arr.push({
      icon: TariffOptionIcon.Speed,
      value: speed.localized_name.split(' ')[0],
      text: speed.localized_name.split(' ')[1],
    });
  }

  if (tv) {
    arr.push({
      icon: TariffOptionIcon.Tv,
      value: tv.localized_name.split(' ')[0],
      text: tv.localized_name.split(' ')[1],
    });
  }

  if (tariff?.connection_cost) {
    arr.push({
      // смена иконки, если это 555 или 777
      icon: specialTariffNameMaker(tariff?.name) ? TariffOptionIcon.Unlimited : TariffOptionIcon.Connection,
      value: '',
      text: tariff.connection_cost,
      boldFistWord: true,
    });
  }

  if (tariff?.router_rental) {
    arr.push({
      icon: TariffOptionIcon.Router,
      value: '',
      text: tariff.router_rental,
      boldFistWord: true,
    });
  }

  return arr;
};

export const mainCardOptionMapper = ({ options, isIternetOptions, name }: MainCardOptionMapperArgs) => {
  if (!isIternetOptions) return options?.map((item) => ({ ...item, from: item.icon !== TariffOptionIcon.Unlimited && !specialTariffNameMaker(name) }));

  return options?.filter((item) => item.icon !== TariffOptionIcon.Router)?.map((item) => ({
    ...item,
    from: (item.icon !== TariffOptionIcon.Connection && item.icon !== TariffOptionIcon.Unlimited && !specialTariffNameMaker(name)) || item.icon === TariffOptionIcon.Tv,
  }));
};
