export enum TariffOptionIcon {
  Phone = 'phone',
  Globe = 'globe',
  Email = 'email',
  Tv = 'tv',
  Speed = 'speed',
  Router = 'router',
  Connection = 'connection',
  Rub = 'rub',
  PhoneTariff = 'phoneTariff',
  Sim = 'sim',
  Unlimited = 'unlimited',
}

export type TariffCardOptionProps = {
  icon: TariffOptionIcon;
  value: React.ReactNode;
  text: string;
  from?: boolean;
  purple?: boolean;
  className?: string;
  boldFistWord?: boolean;
};
